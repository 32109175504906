/*********************************************************************/
/* START - Universal Flex Box Layout; DO NOT alter these style classes

    (a.) If you need to implement a Flex Box Layout for one of your own divs, then just add one (or more) of the
    core CSS classes to it as follows (either using the 'class' property OR something like 'NgClass'):

        <div id="davesDiv" class="lwFlexColumnContainer lwFlexScalableContent"></div>

    (b.) If you have a classed component that's internal to PrimeNG code and needs to implement a Flex Box Layout,
    then you should make sure that you provide it with either an id or unique class selector for the component at
    the topmost point in the ancestry chain, and then ADD IT to the list of selectors below, like this:

        #DavesSpecialDropDown .ui-dropdown,

        .lwFlexCenteredRowContainer {
            display: flex;
            display: -webkit-box;
            display: -moz-box;
            ...
        }

    (c.) This stylesheet also contains the styling for the 'lwFlexDataGrid' class, which takes the PrimeNG p-table
    component, and hijacks its sub-components so that the table is laid out using Flex Box. This effectively reigns
    in the p-table component so that it doesn't force a parent container with "soft dimensions" to expand, but rather,
    forces the p-table to conform to the dimensions of its parent.

/*********************************************************************/

/* START - .lwFlexColumnContainer Unique Selector List  */
/* END - .lwFlexColumnContainer Unique Selector List  */

.lwFlexColumnContainer {
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box;
  /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Chrome */
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: vertical;
  -webkit-box-direction: normal;
  box-direction: normal;
  -moz-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

/* START - .lwFlexRowContainer Unique Selector List  */

/* END - .lwFlexRowContainer Unique Selector List  */

.lwFlexRowContainer {
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box;
  /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Chrome */
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  -webkit-box-orient: horizontal;
  box-orient: horizontal;
  -moz-box-orient: horizontal;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

/* START - .lwFlexScalableContent Unique Selector List  */

/* END - .lwFlexScalableContent Unique Selector List  */

.lwFlexScalableContent {
  /* Don't EVER change the width/height values of this class;
    the whole idea is that any div that's part of a flexbox
    layout AND is a member of this class will expand itself
    into all available space. Changing these values will cause
    numerous headaches downstream */
  width: 100%;
  height: 100%;
  -webkit-box-flex: 1 1 auto;
  box-flex: 1 1 auto;
  /* OLD - iOS 6-, Safari 3.1-6 */
  -moz-box-flex: 1 1 auto;
  /* OLD - Firefox 19- */
  -webkit-flex: 1 1 auto;
  /* Chrome */
  -ms-flex: 1 1 auto;
  /* IE 10 */
  flex: 1 1 auto;
  min-height: 0;
}

/* Add one of these to either the column or row class to tweak how 
   centering works; 'absolute centering' will put the content in the
   extreme center of your column or row (without stretching it to fill
   the container) while 'Cross Axis Centering' will center the content
   vertically for rows and horizontally for columns (again, without
   stretching the content to fill your container). */

/* START - .lwFlexAbsoluteCentering Unique Selector List  */

/* END - .lwFlexAbsoluteCentering Unique Selector List  */

.lwFlexAbsoluteCentering {
    justify-content: center !important;
    align-items: center !important;
    align-content: center !important;
}

/* START - .lwFlexCrossAxisCentering Unique Selector List  */

/* END - .lwFlexCrossAxisCentering Unique Selector List  */

.lwFlexCrossAxisCentering {
    justify-content: flex-start !important;
    align-items: center !important;
    align-content: center !important;
}

/*********************************************************************/
/* END - Universal Flex Box Layout; DO NOT alter these style classes */
/*********************************************************************/