/* You can add global styles to this file, and also import other style files */

.main-app {
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
    font-family: Arial, Helvetica, sans-serif;
    box-sizing: border-box;
    color: #666;
    caret-color: transparent;
}


.page-header-bar {
    width: 100%;
    height: 32px;
    background-color: #0E1D77;
}

.help-message-block {
    margin: 0.25em;
    padding: 1.25em;
    color: #0E1D77;
    border: 1px solid #0E1D77;
    border-radius: 10px;
    background-color: rgb(14, 29, 119, .18);
}

.help-message {
    font-size: 1em;
}
.message-heading {
    font-size: 1.2em;
}

.stepTitle {
    color: #0E1D77;
    font-size: 1.2rem !important;
}


.wait-message {
    height: 150px !important;
}


.lw-view-window {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.lwInterfaceButton {
    color: #fff !important;
    background: #0E1D77 !important;
    border-radius: 4px;
    font-size: 14px !important;
    padding: 4px 10px;
}

input[type=checkbox] {
    accent-color: #0E1D77;
    transform: scale(1.5);
    margin-right: 5px;
}



.lw-portsmouth-navy-bg {
    background-color: #0E1D77;
    color: #ffffff;
}

.lw-breg-river-bg {
    background-color: #0072EB;
    color: #ffffff;
}

.lw-dark-grey-bg {
    background-color: #666666;
    color: #ffffff;
}

.lw-medium-grey-bg {
    background-color: #B6B6B6;
    color: #000000;
}

.lw-light-grey-bg {
    background-color: #F0F0F0;
    color: #666666;
}

.topBottonPageBorder {
    flex: 1 1 auto;
    height: 40px;
    width: 100%;
}

#lwLogoImg {
    height: 75px;
}

#titleContainer {
    width: 100%;
    height: 105px;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    align-content: flex-start !important;
}

#migrationTitleText {
    font-size: 18px;
    font-family: Arial, Helvetica, sans-serif;
    color: #007bff;
    margin-left: 67px;
    margin-top: -13px;
}

.help-button {
    margin-top: 1em;
}

#learnMore {
    padding: .75em;
}

/***********************************/
/* File Uploader Component Styling */
/***********************************/

angular-file-uploader p {
    display: none !important;
}

.afu-select-btn {
    margin-bottom: 0px !important;
}

.btn-success {
    background-color: #0072EB !important;
    border-color: #0072EB !important;
}

.btn-success:hover {
    background-color: #007bff !important;
    border-color: #007bff !important;
}

.btn-success:active {

    background-color: #0E1D77;
    color: #ffffff;
}
body {
    font-family: Segoe UI, Arial, Helvetica, sans-serif;
    background-color: #f0f0f0;
    margin: 0px;
}

kendo-loader {
    position: fixed !important;
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
}

kendo-loader.hidden {
    display: none !important;
}

.lwUniformBranding .k-loader-segment {
    /*color: #007bff !important;*/
    color: #0072EB !important;
    box-shadow: #ffffff 0px 0px 1px;
    z-index: 20001;
}

kendo-expansionpanel.lwUniformBranding {
    border-color: #cdcdcd !important;
    margin: 10px;
}

kendo-expansionpanel.lwUniformBranding .k-expander-title {
    color: #000000 !important;
    text-transform: none !important;
    font-weight: 550;
}

kendo-button.lwUniformBranding.k-button-solid-base,
button.lwUniformBranding,
.k-button-solid-primary
{
    border-color: #666666;
    color: #FFFFFF;
    background-color: #007bff !important;
    background-image: linear-gradient(rgba(14, 29, 119, 0), rgba(14, 29, 119, 0.02));
    padding: 5px 10px;
    border-radius: 4px;
}


kendo-textbox.lwUniformBranding.k-input-solid,
input.lwUniformBranding.k-checkbox {
    border-color: #666666 !important;
}

input.lwUniformBranding.k-checkbox:checked {
    background-color: #007bff !important;
}

.lwUniformBranding .k-window-titlebar
{
    background-color: #007bff;
    color: #fff;
}

.lwUniformBranding .k-table-thead,
.lwUniformBranding .k-table-header,
.lwUniformBranding .k-table-group-sticky-header,
.lwUniformBranding .k-grid-header {
    background-color: #007bff;
    color: #fff;
    font-weight: 600;
}

.lwUniformBranding .k-grid-header .k-sort-icon,
.lwUniformBranding .k-grid-header .k-sort-order {
    color: #FFFFFF;
}

#lwRibbonTabStrip {
    color: #666 !important;
}

#lwRibbonTabStrip .k-tabstrip-items-wrapper {
    background-color: #007bff !important;
}

#lwRibbonTabStrip .k-tabstrip-items li {
    border-radius: 0px !important;
}

#lwRibbonTabStrip .k-menu li:not(.k-active),
#lwRibbonTabStrip .k-tabstrip-items li:not(.k-active) {
    color: #ffffff;
    background-color: #007bff !important;
}

#lwRibbonTabStrip .k-tabstrip-items li.k-item.k-active,
#lwRibbonTabStrip .k-tabstrip-content {
    background-color: #f0f0f0;
}

#lwRibbonTabStrip .k-tabstrip-content {
    border-left: 0px;
    border-right: 0px;
    border-radius: 0px;
    border-color: #aaaaaa !important;
    font-size: 11px !important;
    padding: 3px !important;
}

#lwRibbonTabStrip .k-button-md,
#lwRibbonTabStrip .k-menu,
#lwRibbonTabStrip .k-menu .k-menu-item,
#lwRibbonTabStrip .k-tabstrip-items-wrapper {
    font-size: 12px !important;
}

#lwRibbonTabStrip #ribbonFileMenu li.k-menu-item>span {
    margin-top: 1px;
    padding-top: 7px !important;
    padding-bottom: 5px !important;
}

#lwRibbonTabStrip .k-tabstrip-content:focus,
#lwRibbonTabStrip li:focus {
    outline-style: none !important;
    box-shadow: none !important;
}

ribbon-button .k-card {
    background-color: #f0f0f0 !important;
    border-color: rgba(0, 0, 0, 0) !important;
}

ribbon-button:hover .k-card:not(.disabled) {
    background-color: #666666 !important;
    color: #ffffff !important;
    cursor: pointer;
}

.lwRibbonPopupItem.k-item {
    font-size: 12px !important;
}

/* ATTENTION: We need to find a way to apply the 'lwUniformBranding' class to
   the popup components that are listed below. Probably we need to use templates
   as described in the Kendo docs. These styles are fine for now, though. */

.k-list-item.k-selected {
    /* Dropdown list - when an item in the option list is selected */
    background-color: #007bff !important;
    color: #FFFFFF !important;
}

.k-menu:not(.k-context-menu)>.k-item {
    color: #FFFFFF;
}

.k-menu:not(.k-context-menu)>.k-item:hover,
.k-menu:not(.k-context-menu)>.k-item.k-hover {
    color: #FFFFFF;
}

.k-menu-group .k-item>.k-link:active {
    color: white;
    background-color: #007bff;
}


.k-splitbar:focus,.k-splitbar.k-focus {
    color: #fff;
    background-color: #007bff;
}

.k-overlay {
    opacity: 0.25 !important;
}
kendo-button {
    flex: 0 0 auto !important;
}

kendo-textbox {
    max-width: 400px;
}

kendo-textbox {
    max-width: 400px;
}

kendo-combobox {
    max-width: 400px;
}

kendo-dropdownlist {
    max-width: 400px;
}


kendo-label {
    flex: 0 0 auto !important;
    min-width: 175px;
}

fieldset {
    border: 0px !important;
}

span.mandatoryAsterisk {
    flex: 0 0 auto !important;
    min-width: 6px;
}

.sectionTitle {
    font-size: 14px;
    font-weight: 700;
    margin-top: 1.75em;
}

.sectionTitle:first-of-type {
    font-size: 14px;
    font-weight: 700;
    margin-top: 0px;
}

.k-radio {
    border-color: #007bff;
}

.k-radio:checked, .k-radio.k-checked {
    border-color: #007bff;
    color: #FFFFFF;
    background-color: #007bff;

}
